import React, { useState } from 'react';
import { uploadRewardsData } from '../../../data-upload-scripts/push-reward-data';
import { getEnvKey } from '../../../utils/getEnvKey';
import { useUserProgress } from '../../../state/use-user-progress';
import { Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { invalidateCache } from '../../../utils/cacheReader';
import { pushJamNotes } from '../../../data-upload-scripts/push-jam-notes-data';

const Reset: React.FC = () => {
    const { userProgress, setUserProgress, addExperience } = useUserProgress();
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const navigate = useNavigate();

    if (getEnvKey('ENV') !== 'staging') {
        return null;
    }

    const resetRewards = () => {
        setButtonEnabled(false);
        uploadRewardsData().then(() => {}).finally(() => setButtonEnabled(true));
    }

    const resetJamNotes = () => {
        setButtonEnabled(false);
        pushJamNotes().then(() => {}).finally(() => setButtonEnabled(true));
    };

    const resetXP = () => {
        setUserProgress((prev) => ({
            ...prev,
            xp: 0,
            level: 1,
            stage: 0,
          }));
    }

    const resetStreak = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        setUserProgress((prev) => ({
            ...prev,
            streak: 0,
            lastLogin: Timestamp.fromDate(yesterday),
          }));
    }

    const resetLastLogin = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        setUserProgress((prev) => ({
            ...prev,
            lastLogin: Timestamp.fromDate(yesterday),
          }));
    }

    const navigateHome = () => {
        navigate('/home');
    };

    const clearRewardCache = () => {
        invalidateCache('rewards');
    };

    const clearJamNotesCache = () => {
        invalidateCache('jam-glass-notes');
    }

    return (
        <div className="flex flex-col items-center justify-center w-full h-screen overflow-y-scroll py-4">
            <div className="flex flex-col items-stretch justify-center w-3/4">
                <button className="px-2 py-2 bg-slate-900 text-white rounded-md  mb-4" onClick={navigateHome}>Navigate Home</button>
                <div className="px-2 py-2 bg-white rounded-md  mb-4">
                    <ul>
                        <li>Level: {userProgress.level}</li>
                        <li>Stage: {userProgress.stage}</li>
                        <li>XP: {userProgress.xp}</li>
                        <li>Streak: {userProgress.streak}</li>
                        <li>Last Login: {`${userProgress.lastLogin?.toDate().getDate()}`}</li>
                    </ul>
                </div>
                <hr className="my-1"/>
                <button disabled={!buttonEnabled} className="p-5 bg-white rounded-md disabled:animate-pulse disabled:bg-slate-500 my-2" onClick={resetRewards}>Reset Rewards</button>
                <button className="px-2 py-2 bg-white rounded-md  my-2" onClick={clearRewardCache}>Clear Reward Cache</button>
                <hr className="my-1"/>
                <button disabled={!buttonEnabled} className="p-5 bg-white rounded-md disabled:animate-pulse disabled:bg-slate-500 my-2" onClick={resetJamNotes}>Reset Jam Notes</button>
                <button className="px-2 py-2 bg-white rounded-md  my-2" onClick={clearJamNotesCache}>Clear Jam Glass Cache</button>
                <hr className="my-1"/>
                <button className="px-2 py-2 bg-white rounded-md  my-2" onClick={resetXP}>Reset XP</button>
                <button className="px-2 py-2 bg-white rounded-md  my-2" onClick={ () => addExperience(1000) }>Add 1000 XP</button>
                <hr className="my-1"/>
                <button className="px-2 py-2 bg-white rounded-md  my-2" onClick={resetStreak}>Reset Streak</button>
                <button className="px-2 py-2 bg-white rounded-md  my-2" onClick={resetLastLogin}>Reset LastLogin</button>
            </div>
        </div>
    );
};

export default Reset;