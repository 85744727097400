import React from 'react';
import streakFlame from '../../assets/streak/streak-flame.png';

interface StreakModalProps {
  streak: number;
  onClose: () => void;
}

const StreakModal: React.FC<StreakModalProps> = ({ streak, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-white bg-black bg-opacity-70">
      <div className="flex flex-col items-center justify-center w-[21.625rem] bg-jam-glass p-4 rounded-2xl border-4 border-gray-900">
        <div className="flex items-center">
            <img src={streakFlame} alt="streakFlame" className="h-28"/>
            <div className="flex flex-col">
                <span className="text-[3rem] font-bold leading-none text-center">{streak} Tag{streak > 1 ? 'e' : ''}</span>
                <span className="mt-3 px-2 py-1 bg-amber-800 rounded-md">Streak Bonus +350 XP!</span>
            </div>
        </div>

        <button
            className="w-2/3 p-2 mx-8 mt-4 font-home font-bold text-1xl bg-gray-900 rounded-xl shadow-2xl"
            onClick={onClose}
        >
            Sweet! 
        </button>
      </div>
    </div>
  );
};

export default StreakModal;