import { doc, setDoc } from "firebase/firestore";
import { db } from '../firebaseconfig';

const rewardsData = [
  {
    level: 1,
    levelName: 'Spooky Maus',
    stages: [
      { level: 1, stage: 1, type: 'present', burned: false, ressource: 'bday-2024' },
      { level: 1, stage: 2, type: 'image', burned: false, ressource: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/rewards%2Fimages%2Frunning%20_in_rain.jpg?alt=media&token=56e2a093-a729-468d-8f19-3b68bb8f7026' },
      { level: 1, stage: 3, type: 'link', burned: false, ressource: 'https://open.spotify.com/playlist/3u1kkzyUyde5UV8Jir5CPN?si=2c42c46994594086&pt=16ba0e232a1c653caaaae956ed6b99e2' },
      { level: 1, stage: 4, type: 'audio', burned: false, ressource: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/rewards%2Fimages%2Fbaeronair.m4a?alt=media&token=046e97c9-9e56-4984-b124-019c554aa606' },
      { level: 1, stage: 5, type: 'text', burned: false, ressource: 'Schau mal auf deinen Schrank rechts oben 🥰' },
      { level: 1, stage: 6, type: 'video', burned: false, ressource: 'Z2dcvcSTc4E' },
      { level: 1, stage: 7, type: 'image', burned: false, ressource: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/rewards%2Fimages%2F2017.jpg?alt=media&token=2cbe7699-d5ae-4c1f-b8b7-b12f14e2c1ce' },
      { level: 1, stage: 8, type: 'hot', burned: false, ressource: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/rewards%2Fimages%2Fhot-collage.png?alt=media&token=1ece3c7c-cac9-4468-bf21-d0d7e351b67c' },
      { level: 1, stage: 9, type: 'audio', burned: false, ressource: 'https://firebasestorage.googleapis.com/v0/b/gemeinbaer-park.appspot.com/o/rewards%2Fimages%2FMaus%20Reward%20Schwerelos%20v2.m4a?alt=media&token=d25b7868-f728-43fa-873a-e7902f7895cc' },
      { level: 1, stage: 10, type: 'secret', burned: false, ressource: '' },
    ],
  },
  {
    level: 2,
    levelName: 'Kuschel Maus',
    stages: [
      { level: 2, stage: 1, type: 'secret', burned: false, ressource: 'Ahh Bärchen muss noch Content produzieren' },
      { level: 2, stage: 2, type: 'secret', burned: false, ressource: 'Ahh Bärchen muss noch Content produzieren' },
      { level: 2, stage: 3, type: 'secret', burned: false, ressource: 'Ahh Bärchen muss noch Content produzieren' },
      { level: 2, stage: 4, type: 'secret', burned: false, ressource: 'Ahh Bärchen muss noch Content produzieren' },
      { level: 2, stage: 5, type: 'secret', burned: false, ressource: 'Ahh Bärchen muss noch Content produzieren' },
      { level: 2, stage: 6, type: 'secret', burned: false, ressource: 'Ahh Bärchen muss noch Content produzieren' },
      { level: 2, stage: 7, type: 'secret', burned: false, ressource: 'Ahh Bärchen muss noch Content produzieren' },
      { level: 2, stage: 8, type: 'secret', burned: false, ressource: 'Ahh Bärchen muss noch Content produzieren' },
      { level: 2, stage: 9, type: 'secret', burned: false, ressource: 'Ahh Bärchen muss noch Content produzieren' },
      { level: 2, stage: 10, type: 'secret', burned: false, ressource: 'Ahh Bärchen muss noch Content produzieren' },
    ],
  },
];

export const uploadRewardsData = async () => {
  try {
    // Loop through each level
    for (const reward of rewardsData) {
      const { level, levelName, stages } = reward;

      // Create a document for the level
      const levelDocRef = doc(db, 'rewards-level-staging', `level${level}`);
      await setDoc(levelDocRef, { level, levelName });

      // Loop through each stage of the level and add it to a subcollection
      for (const stage of stages) {
        const stageDocRef = doc(db, `rewards-level-staging/level${level}/reward-stages-level${level}`, `stage${stage.stage}`);
        await setDoc(stageDocRef, stage);
      }
    }
    console.log("Bulk upload successful");
  } catch (error) {
    console.error("Error uploading data to Firestore: ", error);
  }
};

