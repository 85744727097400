import React from 'react';
import { useNavigate } from 'react-router-dom';
import ScrollingHeader from '../header/scrolling-header/scrolling-header';
import BackButton from '../utils/back-button/back-button';
import kissBanner from '../../assets/game-list/kiss-banner-x2.png';
import memoryBanner from '../../assets/game-list/memory-banner-x2.png';

const GameList: React.FC = () => {
    const navigate = useNavigate();

    const navigateToGame = (path: string) => {
        navigate(path);
    };

    return (
        <>
            <ScrollingHeader>
                <BackButton className="bg-black" />
            </ScrollingHeader>
            <div className="flex flex-col justify-center items-center w-full flex-wrap gap-5 mt-20 p-5">
                <h1 className="text-4xl text-center text-white text-bold font-glass">Mini Games</h1>
                <h2 className="text-2xl text-center text-white text-bold">Was möchtest du spielen?</h2>
                <div className="h-40 rounded-md overflow-hidden" onClick={() => navigateToGame('/kiss-game')}>
                    <img src={kissBanner} width="320" alt="Kiss Game" />
                </div>
                <div className="h-40 rounded-md overflow-hidden" onClick={() => navigateToGame('/memory-game')}>
                    <img src={memoryBanner} width="320" alt="Memory Game" />
                </div>
            </div>
        </>
    );
};

export default GameList;