import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ScrollingHeader from '../../header/scrolling-header/scrolling-header';
import useAuth from '../../../utils/useAuth';
import { RewardStage } from './reward-models';
import { useUserProgress } from '../../../state/use-user-progress';
import commingSoon from '../../../assets/home/comming-soon.png';
import PresentBday2024 from './present/bday-2024';
import BackButton from '../../utils/back-button/back-button';
import VideoPlayer from '../../utils/video-player';

const RewardDetail: React.FC = () => {
    useAuth();
    const { updateStage } = useUserProgress();
    const location = useLocation();
    const reward: RewardStage = location.state;

    const handleNavigateBack = () => {
        updateStage(reward.level, reward.stage, { burned: true });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(!reward) {
        return (
            <>
                <BackButton className="bg-black" />
                <div className="flex flex-col items-center justify-center h-screen w-full">
                    <h1 className="text-3xl font-bold text-center text-white">Ooops! Nichts hier</h1>
                    <img src={commingSoon} alt="" />
                </div>
            </>
        )
    }

    return (
        <div className="bg-gray-800 w-full h-screen pt-20 overflow-y-hidden">
            <ScrollingHeader>
                <BackButton className="bg-black" callback={handleNavigateBack} />
            </ScrollingHeader>
            <div className="flex flex-col justify-center mx-0">
                <h1 className="mb-6 text-center text-white text-4xl text-bold font-gaming"> Level { reward.level } - Stage { reward.stage } </h1>
                <div className='p-4 bg-jam-glass text-white h-screen'>
                    {
                        reward.type === 'image' && <img src={reward.ressource} alt={`Level ${reward.level} - Stufe ${reward.stage}`} />
                    }
                    {
                        reward.type === 'hot' && <img src={reward.ressource} alt={`Level ${reward.level} - Stufe ${reward.stage}`} />
                    }
                    {
                        reward.type === 'text' && <p>{reward.ressource}</p> 
                    }
                    {
                        reward.type === 'link' && (
                            <div className="flex justify-center mt-2">
                                <a className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-[1.2rem] px-5 py-2.5 text-center me-2 mb-2" href={reward.ressource}>
                                    Flummi Playlist auf Spotify
                                </a>
                            </div>
                        ) 
                    }
                    {
                        reward.type === 'present' && <PresentBday2024 />
                    }
                    {
                        reward.type === 'audio' && (
                            <div className='w-full flex justify-center'>
                                <audio controls controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar" autoPlay>
                                    <source src={reward.ressource} type='audio/x-m4a' />
                                </audio>
                            </div>
                        )
                    }
                    {
                        reward.type === 'video' && <VideoPlayer id={reward.ressource} />
                    }
                </div>
            </div>
        </div>
    );
};

export default RewardDetail;
