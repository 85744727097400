import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseconfig";

const data = [
  {
    "id": 1,
    "text": "Hey mein Mäuschen. Viel Spaß mit dem Glas. Wenn du runterscrollst, siehst du die bereits gezogenen Zettel.",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 2,
    "text": "Aus DIR und MIR wird ein UNS. Das ist wunderbarste das ich für jemandem empfunden habe.",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 3,
    "text": "Du kannst stolz auf dich sein!",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 4,
    "text": "Dein Popo ist so schön",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 5,
    "text": "Deine liebevolle Art ist so wertvoll für mich",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 6,
    "text": "Du flummig fruchtig süße Zaubermaus <3",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 7,
    "text": "Deine Haare sind sooooo schön!",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 8,
    "text": "Ich liebe es wie du dich freust",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 9,
    "text": "Mit dir möchte ich in eine Zukunft planen",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 10,
    "text": "Du hast mir gezeigt was Liebe ist",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 11,
    "text": "Ich hatte heute das Gefühl: Solange ich mit dir bin ist es egal was wir machen. Das war gemütlich :)",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 12,
    "text": "Mit dir tanze ich als ob niemand zusieht!",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 13,
    "text": "Ich bin endlos verliebt!",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 14,
    "text": "Mit dir lache ich herzlich",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 15,
    "text": "Ich finde deine Kreativität attraktiv",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 16,
    "text": "Ich mage deine süßen Namen für alles",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 17,
    "text": "Du bist so süß, wenn du müde bist",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 18,
    "text": "Du beruhigst mich",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 19,
    "text": "Mir geht es besser, wenn ich an dich denke",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 20,
    "text": "Du machst mich glücklich!",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 21,
    "text": "Es kribbelt so schön, wenn ich dich ansehe!",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 22,
    "text": "Ich liebe dich",
    "pulled": true,
    "jarVersion": 1
  },
  {
    "id": 23,
    "text": "Die neuen Marmeladenglas Momente haben eine besondere Bedeutung. Erzähl ich dir noch :*",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 24,
    "text": "Du akzeptierst mich",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 25,
    "text": "Du teilst meinen Humor",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 26,
    "text": "Ich sehe dir in die Augen und du berührst mich",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 27,
    "text": "Wir haben eine ähnliche Meinung zu Kindern",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 28,
    "text": "Wir werden nicht laut, wenn wir ernste Themen diskutieren",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 29,
    "text": "Ich fühle mich sehr geehrt, dass ich die erste Person bin die du an dich herangelassen hast",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 30,
    "text": "Dein Ausstrahlung fängt mich",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 31,
    "text": "Wir können auf wenig Raum viel Zeit miteinander verbringen. 3 Zimmer Küche Bad wären da sicher ein Palast für uns",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 32,
    "text": "Wir beide mögen Ruhe und leise Beschäftigungen, sind aber auch wilde Partymäuse/bären :)",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 33,
    "text": "Ich habe Angst das du irgendwann rausfindest das ich nicht bin was du suchst.",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 34,
    "text": "Mit dir habe ich keine Angst vor einem Paartanz. Randomfakt über Niklas Ängste lol.",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 35,
    "text": "Ich fühle mich sicher bei dir",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 36,
    "text": "Jemanden zu finden bei dem das alles so ist ... ",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 37,
    "text": "... beantwortet Fragen die mich Zweifeln lassen haben und ...",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 38,
    "text": "... ich kann mich glücklich schätzen, ...",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 39,
    "text": "... dass dein Herz so lange bei mir geblieben ist ...",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 40,
    "text": "... bis ich das ich das für mich erschließen konnte.",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 41,
    "text": "Danke Jana. Kuss <3",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 42,
    "text": "So! Jetzt geht's weiter mit der üblichen Marmelade...",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 43,
    "text": "Ich mag halb ernst halb \"Scheinkram-Gespräche\" mit dir",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 44,
    "text": "Ich denke viel an dich",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 45,
    "text": "Wow!",
    "pulled": true,
    "jarVersion": 2
  },
  {
    "id": 46,
    "text": "Mir läuft es kalt den Rücken runter, wenn ich daran denke deinen Popo nie wieder anfassen zu können",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 47,
    "text": "Du und ich, undercover auf einem Maskenball in einem alten Anwesen. Einen Abend lang tanzen, bis uns die edle Gesellschaft zu überdrüssig wird und wir auf einen kleinen Balkon fliehen. Im Mondlicht liegen wir uns in den Armen. Die Gespenster erschrecken sich, sobald sie Zeuge unserer wilden Küsse werden.",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 48,
    "text": "Danke das du dich deinen Dämonen stellst",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 49,
    "text": "Mit dir mal Kürbise schnitzen <3",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 50,
    "text": "Spookymäuschen 🐭❤️",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 51,
    "text": "Süßes oder Versautes 😏🔥",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 52,
    "text": "Ohne dich ist alles gruselig! ❤️🥺",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 53,
    "text": "Mein Herz ist verzaubert von dir. 🔮💘",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 54,
    "text": "Hättest du Mal Lust auf ein Partner Fotoshooting? 📸🥰",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 55,
    "text": "Te amo mi corazón ❤️",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 56,
    "text": "Durch dich gebe ich mehr auf mich acht. Danke ✨",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 57,
    "text": "Ich freue mich, wenn ich nach dem Aufwachen deine Nachricht auf meine Display sehe. Danke! 🥰",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 58,
    "text": "Wenn ich gerade nicht bei dir bin, kannst du dir sicher sein das ich mich gerade sehr auf dich freue 😘",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 59,
    "text": "Du und ich auf dem Blumengartenkonzert. Der letzte Song ist gespielt. Zusammen auf die Fläche und tanzen als wenn niemand sonst da wäre. 🌸🎶",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 60,
    "text": "Du hast da etwas Glitzer auf deiner Seele. 🌟❤️",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 61,
    "text": "Marmeladen Fakt: Die Inspiration für diese Zettel kommen aus unserem Chatverlauf. Ich merke einfach immer wieder was für ein toller Mensch du bist und wie froh ich bin das du wir uns gefunden haben. Ich liebe dich Jana ❤️.",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 62,
    "text": "Danke das du mich in die Welt der Konzerte geführt hast. Du rockst! 🎸🥰",
    "pulled": false,
    "jarVersion": 3
  }, {
    "id": 63,
    "text": "Du bist für mich die heißeste Frau der Welt. Nähe, Küssen und Sex mit dir ist unbeschreiblich schön. 🔥❤️",
    "pulled": false,
    "jarVersion": 3
  }
];

async function pushJamNotes() {
  try {
      for (const note of data) {
          // Correct document reference: collection(db, 'jam-glass-notes') and then doc() with note.id
          const docRef = doc(collection(db, 'jam-glass-notes-staging'), note.id.toString());
          await setDoc(docRef, note);
          console.log('Document written with ID: ', docRef.id);
      }
  } catch (error) {
      console.error('Error adding document: ', error);
  }
}

export { pushJamNotes };