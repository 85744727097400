// Define a type for your fetch function
type FetchFunction<T> = () => Promise<T>;

// Define an interface for the cache entry
interface CacheEntry<T> {
  timestamp: number;
  data: T;
}

/**
 * Retrieves data from cache or fetches it from the backend if not cached or expired.
 *
 * @param key - Unique key for storing and retrieving cached data
 * @param fetchFunction - Function to fetch data from the backend
 * @param cacheDurationMs - Duration in milliseconds for which the cache is valid (default: 2 hours)
 * @returns A Promise that resolves to the requested data
 */
export function getCachedData<T>(
  key: string,
  fetchFunction: FetchFunction<T>,
  cacheDurationMs = 0.25 * 60 * 60 * 1000 // Default to 15 min
): Promise<T> {
  const cached = localStorage.getItem(key);
  const now = Date.now();
  const cacheEnabled = process.env.REACT_APP_CACHE_ENABLED === 'true';

  if (cached && cacheEnabled) {
    const cacheEntry: CacheEntry<T> = JSON.parse(cached);

    if (now - cacheEntry.timestamp < cacheDurationMs) {
      console.log('Using cached data for key:', key);
      return Promise.resolve(cacheEntry.data);
    }
  }

  return fetchFunction().then((data) => {
    const cacheEntry: CacheEntry<T> = { timestamp: now, data };
    localStorage.setItem(key, JSON.stringify(cacheEntry));
    return data;
  });
}

/**
 * Invalidates the cache for a given key.
 *
 * @param key - Unique key for the cached data to invalidate
 */
export function invalidateCache(key: string): void {
  console.log('Invalidating cache for key:', key);
  localStorage.removeItem(key);
}
