import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../pages/login/login';
import Home from '../pages/home/home';
import JamGlass from '../pages/jam-glass/jam-glass';
import RewardDetail from '../pages/rewards/reward-detail';
import Rewards from '../pages/rewards/rewards';
import { UserProgressProvider } from '../../state/user-progress-provider';
import useAuth from '../../utils/useAuth';
import Reset from '../pages/reset/reset';
import GameList from '../mini-games/game-list';
import MemoryGame from '../mini-games/memory-game.tsx/memory-game';
import KissGame from '../mini-games/kiss-game';

const Main: React.FC = () => {
  const { user } = useAuth();

  return (
    <>
      {user ? (
        <UserProgressProvider>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/jam-glass" element={<JamGlass />} />
            <Route path="/mini-games" element={<GameList />} />
            <Route path="/kiss-game" element={<KissGame />} />
            <Route path="/memory-game" element={<MemoryGame />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/rewards/:id" element={<RewardDetail />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/*" element={<Home />} />
          </Routes>
        </UserProgressProvider>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Main;
