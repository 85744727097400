import { doc, collection, setDoc, getDoc, getDocs, updateDoc, Timestamp, orderBy, query } from "firebase/firestore";
import React from "react";
import { createContext, useEffect, useState } from "react";
import { db } from "../firebaseconfig";
import Loading from "../components/utils/loading/loading";
import { getCachedData, invalidateCache } from "../utils/cacheReader";
import { RewardLevel, RewardStage } from "../components/pages/rewards/reward-models";
import StreakModal from "../components/streak/streak-modal";
import { getEnvKey } from "../utils/getEnvKey";

const IS_TESTING = false; // Set to true during development/testing
const TEST_INTERVAL_MS = 1000 * 15; // 15 seconds for testing
const PRODUCTION_INTERVAL_MS = 1000 * 60 * 60 * 24; // 24 hours for production
const STREAK_TIME_INTERVAL_MS = IS_TESTING ? TEST_INTERVAL_MS : PRODUCTION_INTERVAL_MS;


export interface UserProgress {
  level: number;
  stage: number;
  xp: number;
  streak: number;
  lastLogin: Timestamp | null,
}

export type UserProgressContextType = {
  userProgress: UserProgress;
  setUserProgress: React.Dispatch<React.SetStateAction<UserProgress>>;
  levels: Array<RewardLevel>;
  setLevels: React.Dispatch<React.SetStateAction<Array<RewardLevel>>>;
  addExperience: (xp: number) => void;
  updateStage: (
    levelNumber: number,
    stageNumber: number,
    newStageData: Partial<RewardStage>
  ) => Promise<void>;
  xpNeededPerStage: number;
  refreshLevelData: () => void;
};

export const defaultUserProgress: UserProgress = {
  level: 1,
  stage: 0,
  xp: 40,
  streak: 0,
  lastLogin: null,
};

async function updateUserProgress(userProgress: UserProgress): Promise<void> {
  const docRef = doc(collection(db, `config-${getEnvKey('ENV')}`), 'user-progress');
  await setDoc(docRef, userProgress);
}

async function getUserProgress(): Promise<UserProgress | null> {
  try {
    const docRef = doc(db, `config-${getEnvKey('ENV')}`, 'user-progress');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data() as UserProgress;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching document:', error);
    return null;
  }
}

async function getRewardLevels(): Promise<Array<RewardLevel>> {
  const fetchNotes = async (): Promise<Array<RewardLevel>> => {
    try {
        const rewardsLevel: Array<RewardLevel> = [];
        const levelsCollectionRef = collection(db, `rewards-level-${getEnvKey('ENV')}`);
        const levelsSnapshot = await getDocs(levelsCollectionRef);

        for (const levelDoc of levelsSnapshot.docs) {
            const levelData = levelDoc.data();

            const stagesCollectionRef = collection(db, `rewards-level-${getEnvKey('ENV')}/${levelDoc.id}/reward-stages-${levelDoc.id}`);
            const stagesQuery = query(stagesCollectionRef, orderBy('stage'));
            const stagesSnapshot = await getDocs(stagesQuery);

            const stages: any[] = stagesSnapshot.docs.map(stageDoc => stageDoc.data());

            rewardsLevel.push({
                level: levelData.level,
                levelName: levelData.levelName,
                stagesCount: stages.length,
                stages: stages,
            });

        }
        return rewardsLevel;
    } catch (error) {
        console.error('Error fetching notes:', error);
        throw error;
    } finally {
        
    }
  };

  return getCachedData('rewards', fetchNotes)
}

type Progress = { level: number, stage: number, maxXP: number };
const checkStageProgress = (userProgress: UserProgress, levelMetadata: Map<number, number>, xpNeededPerStage: number): Progress | null => {
  const totalStagesAchieved = Math.floor(userProgress.xp / xpNeededPerStage);
  const totalStages = Array.from(levelMetadata.values()).reduce((sum, stages) => sum + stages, 0);
  const maxXP = totalStages * xpNeededPerStage;

  // Prepare levels data
  const levels = Array.from(levelMetadata.entries())
    .map(([level, stagesCount]) => ({ level, stagesCount }))
    .sort((a, b) => a.level - b.level);

  // Compute cumulative stages
  let cumulativeStages = 0;
  const levelsWithCumulativeStages = levels.map((level) => {
    const levelInfo = {
      ...level,
      cumulativeStages,
    };
    cumulativeStages += level.stagesCount;
    return levelInfo;
  });

  let userLevel: number = 0;
  let userStage: number = 0;
  let foundLevel = false;

  for (const level of levelsWithCumulativeStages) {
    if (totalStagesAchieved < level.cumulativeStages + level.stagesCount) {
      userLevel = level.level;
      userStage = totalStagesAchieved - level.cumulativeStages;
      foundLevel = true;
      break;
    }
  }

  if (!foundLevel) {
    const lastLevel = levelsWithCumulativeStages[levelsWithCumulativeStages.length - 1];
    userLevel = lastLevel.level;
    userStage = lastLevel.stagesCount;
  }

  // Now, userLevel and userStage are guaranteed to be numbers
  if (
    userLevel !== userProgress.level ||
    userStage !== userProgress.stage
  ) {
    return {
      level: userLevel,
      stage: userStage,
      maxXP: maxXP,
    }
  } else {
    return null;
  }
};

export const UserProgressContext = createContext<UserProgressContextType | undefined>(undefined);

export const UserProgressProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userProgress, setUserProgress] = useState<UserProgress>(defaultUserProgress);
  const [levelMetadata, setLevelMetadata] = useState<Map<number, number>>(new Map());
  const [showStreakModal, setShowStreakModal] = useState<boolean>(false);
  const [levels, setLevels] = useState<Array<RewardLevel>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [maxXP, setMaxXP] = useState<number>(0);
  const xpNeededPerStage = 2000;

  function addExperience(xp: number): void {
    setUserProgress((prev) => {
      const newXP = Math.min(prev.xp + xp, maxXP);
      return {
        ...prev,
        xp: newXP,
      };
    });
  }
  

  async function refreshLevelData(): Promise<void> {	
    try {
      // Initiate both requests concurrently
      const [userProgressData, rewardLevelsData] = await Promise.all([
        getUserProgress(),
        getRewardLevels(),
      ]);
  
      // Handle user progress data
      if (userProgressData) {
        setUserProgress(userProgressData);
      } else {
        console.error('No user progress data!');
      }
  
      // Handle reward levels data
      if (rewardLevelsData) {
        setLevels(rewardLevelsData);
        setLevelMetadata(new Map(rewardLevelsData.map((level) => [level.level, level.stages.length])));

        const totalStages = rewardLevelsData.reduce((sum, level) => sum + level.stages.length, 0);
        const calculatedMaxXP = totalStages * xpNeededPerStage;
        setMaxXP(calculatedMaxXP);
      } else {
        console.error('No reward levels data!');
      }
  
    } catch (error) {
      console.error('Failed to retrieve data:', error);
    } finally {
      // Set loading to false after both requests have completed
      setLoading(false);
    }
  }
  

  async function updateStage(
    levelNumber: number,
    stageNumber: number,
    newStageData: Partial<RewardStage>
  ): Promise<void> {
    try {
      const stageDocRef = doc(
        db,
        `rewards-level-${getEnvKey('ENV')}/level${levelNumber}/reward-stages-level${levelNumber}`,
        `stage${stageNumber}`
      );
      await updateDoc(stageDocRef, newStageData);

      // Update local state
      setLevels((prevLevels) => {
        return prevLevels.map((level) => {
          if (level.level === levelNumber) {
            const updatedStages = level.stages.map((stage) => {
              if (stage.stage === stageNumber) {
                return { ...stage, ...newStageData };
              } else {
                return stage;
              }
            });
            return { ...level, stages: updatedStages };
          } else {
            return level;
          }
        });
      });

      invalidateCache('rewards');
      await refreshLevelData();
    } catch (error) {
      console.error("Error updating stage in Firestore:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await refreshLevelData();
    }
  
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && levelMetadata.size > 0) {
      const progress: Progress | null = checkStageProgress(userProgress, levelMetadata, xpNeededPerStage);
      if(progress) {
        setUserProgress((prev) => ({
          ...prev,
          level: progress.level,
          stage: progress.stage,
        }));
      }
      
      updateUserProgress(userProgress);
      console.log('User progress updated');
    }
  }, [loading, userProgress, levelMetadata]);

  useEffect(() => {
    if (!loading) {
      const now = new Date();
      const lastLoginDate = userProgress.lastLogin ? userProgress.lastLogin.toDate() : null;
  
      // Normalize times based on testing or production mode
      const intervalMs = STREAK_TIME_INTERVAL_MS;
  
      // For testing, use seconds instead of days
      const normalizeTime = (date: Date) => {
        if (IS_TESTING) {
          return new Date(Math.floor(date.getTime() / intervalMs) * intervalMs);
        } else {
          return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        }
      };
  
      const today = normalizeTime(now);
      const lastLogin = lastLoginDate ? normalizeTime(lastLoginDate) : null;
  
      let newStreak = userProgress.streak;
      const streakXP = 350;
  
      if (lastLogin) {
        const timeDifference = today.getTime() - lastLogin.getTime();
        const intervalDifference = timeDifference / intervalMs;
  
        if (intervalDifference === 0) {
          // Same interval, do nothing
          console.log('User has already logged in during this interval.');
        } else if (intervalDifference === 1) {
          // Next interval, increment streak
          newStreak = userProgress.streak + 1;
          setShowStreakModal(true);
          addExperience(streakXP);
        } else {
          // Missed intervals, reset streak
          newStreak = 1;
          setShowStreakModal(true);
          addExperience(streakXP);
        }
      } else {
        // First login, start streak
        newStreak = 1;
        setShowStreakModal(true);
        addExperience(streakXP);
      }
  
      // Update user progress with new streak and last login date
      setUserProgress((prev) => ({
        ...prev,
        streak: newStreak,
        lastLogin: Timestamp.fromDate(now),
      }));
  
      // Update the user progress in Firestore
      updateUserProgress({
        ...userProgress,
        streak: newStreak,
        lastLogin: Timestamp.fromDate(now),
      });
    }
  }, [loading]);
  
  

  if (loading) {
    return <Loading loading={loading}  />
  }

  return (
    <UserProgressContext.Provider value={{ userProgress, setUserProgress, levels, setLevels, addExperience, updateStage, xpNeededPerStage, refreshLevelData }}>
      {children}
      {showStreakModal && (
        <StreakModal streak={userProgress.streak} onClose={() => { setShowStreakModal(false);} } />
      )}
    </UserProgressContext.Provider>
  );
};
